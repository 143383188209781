.featured .box {
  box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
  border-radius: 6px;
  text-align: center;
  padding: 30px;
  cursor: pointer;
  background-color:#27ae60;
}
.featured img {
  width: 65px;
  height: 65px;
  margin: auto;
}
.box1 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Horizontally center the content */
  justify-content: center; /* Vertically center the content */
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}
