/* Footer Contact Section */
.footerContact {
  background-color: #27ae60;
  padding: 40px 20px;
  color: #fff;
  text-align: center;
}

.footerContact h1 {
  font-size: 32px;
  margin-bottom: 10px;
}

.footerContact p {
  font-size: 16px;
  margin-bottom: 20px;
}

.footerContact .btn5 {
  background-color: #fff;
  color: #27ae60;
  padding: 10px 20px;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.footerContact .btn5:hover {
  background-color: #fff;
  opacity: 0.9;
}

/* Main Footer Section */
footer {
  background-color: #1d2636;
  padding: 50px 20px;
  color: #fff;
}

footer .container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
  justify-content: center;
}

footer .logo {
  text-align: center;
}

footer img {
  width: 150px;
  margin: 0 auto;
}

footer h2 {
  font-weight: 500;
  margin-top: 20px;
  font-size: 22px;
}

footer p {
  color: #ccc;
  margin: 15px 0;
  line-height: 1.6;
}

footer input {
  background-color: #fff;
  padding: 15px;
  width: 100%;
  border-radius: 5px;
  border: none;
  font-size: 14px;
}

footer input:focus {
  outline: 2px solid #27ae60;
}

footer button {
  background-color: #27ae60;
  color: #fff;
  padding: 15px 20px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;
  transition: all 0.3s ease;
}

footer button:hover {
  background-color: #1e8c4d;
}

footer h3 {
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 18px;
}

footer ul {
  list-style-type: none;
  padding: 0;
}

footer ul li {
  color: #ccc;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.5;
}

footer ul li:hover {
  color: #fff;
}

/* Legal Section */
.legal {
  text-align: center;
  padding: 20px;
  background: #1d2636;
  color: rgba(255, 255, 255, 0.7);
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 14px;
}

/* Responsive Design */
@media screen and (max-width: 800px) {
  .footerContact h1 {
    font-size: 28px;
  }

  .footerContact p {
    font-size: 14px;
  }

  footer .container {
    grid-template-columns: 1fr;
    text-align: center;
  }

  footer input {
    margin-bottom: 10px;
  }

  footer ul {
    text-align: center;
  }

  footer ul li {
    display: inline-block;
    margin: 0 10px;
  }
}
