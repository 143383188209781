.hero {
  background-image: url("../../../../public/images/banner.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 90vh;
  width: 100%;
}
.hero .container {
  padding-top: 10%; /* Reduce top padding */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 15px; /* Space between icon and heading */
}

.animation-image {
  max-width: 120px;
  max-height: 120px;
  margin: 0 auto; /* Center the icon */
  padding: 0; /* Remove unnecessary padding */
}

.hero h1 {
  color: #fff;
  font-size: 50px; /* Adjusted for better spacing */
}

.hero p {
  color: #fff;
  opacity: 0.8;
  margin-bottom: 20px; /* Space between paragraph and form */
}

/* Booking Box Styling */
form {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  gap: 10px; /* Space between input fields */
  flex-wrap: wrap; /* Ensures responsiveness */
  margin-top: 20px;
  align-items: center;
  width: 80%; /* Reduce width to 80% */
  justify-content: center; /* Center the form */
  margin-left: auto; /* Center the form horizontally */
  margin-right: auto; /* Center the form horizontally */
}

form input,
form select {
  padding: 3px;
  flex: 1; /* Ensures equal width for inputs */
  min-width: 150px;
  border: 1px solid rgba(128, 128, 128, 0.2);
  border-radius: 5px;
}

form button {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  align-self: flex-end; /* Aligns button properly */
}

form .box {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 800px) {
  .hero {
    height: auto; /* Allows for flexible height */
    padding-bottom: 20px;
  }

  .hero .container {
    padding-top: 20%; /* Adjusted for mobile view */
  }

  form {
    flex-direction: column;
    gap: 15px;
    width: 100%; /* Full width on mobile */
  }
  form button {
    display: flex;
    justify-content: center;
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    align-self: center; /* Center the button */
  }
}

@import url('https://fonts.googleapis.com/css?family=Cairo');


.title {
  font-family: "Cairo";
  text-align: center;
  color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 
  letter-spacing: 1px;
  line-height:2
}
h3{
  font-size: 30px;
  margin-top: 0;
  margin-bottom: 0;
}
h99 {
  background-image: url(https://media.giphy.com/media/26BROrSHlmyzzHf3i/giphy.gif);
  background-size: cover;
  color: transparent;
  -moz-background-clip: text;
  -webkit-background-clip: text;
  text-transform: uppercase;
  font-size: clamp(24px, 5vw, 55px);
}

/* styling my button */

.white-mode {
  text-decoration: none;
  padding: 7px 10px;
  background-color: #122;
  border-radius: 3px;
  color: #FFF;
  transition: .35s ease-in-out;
  position: absolute;
  left: 15px;
  bottom: 15px;
  font-family: "Montserrat";
}

.white-mode:hover {
  background-color: #FFF;
  color: #122;
}
