*
{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.form{
    box-shadow: 2px 6px 100px #000000;
}
.back{
    background-position: center;
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    background-attachment: fixed;
    margin: 0; 
    padding: 0; 
    height: 100vh; 
    overflow: hidden; 

}
.container5{background-position: center;
   
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw; 
    height: 100vh; 
  
    align-items: center;}
    .col-12{
        text-align: center;
    }
    .setleft{
        float: left;
        margin-right: 20px;
    }
    .ful{
        height: 500px;
        object-fit: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-repeat: no-repeat;
        background-attachment: fixed;
    }
    .text-decoration-none{
        padding: 20px;
    }